import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import {
  ImageAuxData,
  SeoSocialShareData,
  SeoMetaTag,
} from '../../model/seo-social-share.model';
import { GlobalObjectService } from '../../../shared/services/global-object.service';

@Injectable({
  providedIn: 'root',
})
export class SeoSocialShareService {
  constructor(
    private readonly metaService: Meta,
    private readonly titleService: Title,
    private globalObject: GlobalObjectService
  ) {
    this.documentRef = this.globalObject.getDocument();
  }

  documentRef: Document;

  public normalizeTitle(title: string) {
    return title.replaceAll(' ', '-');
  }

  public setData(data: SeoSocialShareData): void {
    this.setSection(data.section);
    this.setKeywords(data.keywords);
    this.setTitle(data.title);
    this.setType(data.type);
    this.setDescription(data.description);
    this.setImage(data.image, data.imageAuxData);
    this.setUrl(data.url);
    this.setPublished(data.published);
    this.setModified(data.modified);
    this.setAuthor(data.author);
  }

  private setKeywords(keywords?: string): void {
    if (keywords) {
      this.metaService.updateTag({ name: 'keywords', content: keywords });
    } else {
      this.metaService.removeTag(`name='keywords'`);
    }
  }

  private setSection(section?: string): void {
    if (section) {
      this.metaService.updateTag({ name: 'article:section', content: section });
    } else {
      this.metaService.removeTag(`name='article:section'`);
    }
  }

  private setTitle(title: string = ''): void {
    this.titleService.setTitle(title);
    if (title && title.length) {
      this.metaService.updateTag({ name: 'twitter:title', content: title });
      this.metaService.updateTag({ name: 'twitter:image:alt', content: title });
      this.metaService.updateTag({ property: 'og:image:alt', content: title });
      this.metaService.updateTag({ property: 'og:title', content: title });
      this.metaService.updateTag({ name: 'title', content: title });
      this.metaService.updateTag(
        { itemprop: 'name', content: title },
        `itemprop='name'`
      );
    } else {
      this.metaService.removeTag(`name='twitter:title'`);
      this.metaService.removeTag(`name='twitter:image:alt'`);
      this.metaService.removeTag(`property='og:image:alt'`);
      this.metaService.removeTag(`property='og:title'`);
      this.metaService.removeTag(`name='title'`);
      this.metaService.removeTag(`itemprop='name'`);
    }
  }

  private setType(type?: string): void {
    if (type && type.length) {
      this.metaService.updateTag({ property: 'og:type', content: type });
    } else {
      this.metaService.removeTag(`property='og:type'`);
    }
  }

  private setDescription(description?: string): void {
    if (description && description.length) {
      this.metaService.updateTag({
        name: 'twitter:description',
        content: description,
      });
      this.metaService.updateTag({
        property: 'og:description',
        content: description,
      });
      this.metaService.updateTag({ name: 'description', content: description });
      this.metaService.updateTag(
        { itemprop: 'description', content: description },
        `itemprop='description'`
      );
    } else {
      this.metaService.removeTag(`name='twitter:description'`);
      this.metaService.removeTag(`property='og:description'`);
      this.metaService.removeTag(`name='description'`);
      this.metaService.removeTag(`itemprop='description'`);
    }
  }

  private setImage(image?: string, auxData?: ImageAuxData): void {
    if (image && image.length) {
      this.metaService.updateTag({ name: 'twitter:image', content: image });
      this.metaService.updateTag(
        { itemprop: 'image', content: image },
        `itemprop='image'`
      );
      this.metaService.updateTag({ property: 'og:image', content: image });

      if (auxData && auxData.height) {
        this.metaService.updateTag({
          property: 'og:image:height',
          content: auxData.height.toString(),
        });
      } else {
        this.metaService.removeTag(`property='og:image:height'`);
      }

      if (auxData && auxData.width) {
        this.metaService.updateTag({
          property: 'og:image:width',
          content: auxData.width.toString(),
        });
      } else {
        this.metaService.removeTag(`property='og:image:width'`);
      }

      if (auxData && auxData.alt) {
        this.metaService.updateTag({
          property: 'og:image:alt',
          content: auxData.alt,
        });
        this.metaService.updateTag({
          property: 'twitter:image:alt',
          content: auxData.alt,
        });
      } else {
        this.metaService.removeTag(`property='og:image:alt'`);
        this.metaService.removeTag(`property='twitter:image:alt'`);
      }

      if (auxData && auxData.mimeType) {
        this.metaService.updateTag({
          property: 'og:image:type',
          content: auxData.mimeType,
        });
      } else {
        this.metaService.removeTag(`property='og:image:type'`);
      }

      if (auxData && auxData.secureUrl) {
        this.metaService.updateTag({
          property: 'og:image:secure_url',
          content: auxData.secureUrl,
        });
      } else {
        this.metaService.removeTag(`property='og:image:secure_url'`);
      }
    } else {
      this.metaService.removeTag(`name='twitter:image'`);
      this.metaService.removeTag(`property='twitter:image:alt'`);
      this.metaService.removeTag(`property='og:image'`);
      this.metaService.removeTag(`property='og:image:height'`);
      this.metaService.removeTag(`property='og:image:secure_url'`);
      this.metaService.removeTag(`property='og:image:type'`);
      this.metaService.removeTag(`property='og:image:alt'`);
      this.metaService.removeTag(`property='og:image:width'`);
      this.metaService.removeTag(`itemprop='image'`);
    }
  }

  private setUrl(url?: string): void {
    if (url && url.length) {
      this.metaService.updateTag({ property: 'og:url', content: url });
    } else {
      this.metaService.removeTag(`property='og:url'`);
    }
    this.setCanonicalUrl(url);
  }

  private setPublished(publishedDateString?: string): void {
    if (publishedDateString && (publishedDateString !== 'undefined')) {
      const publishedDate = new Date(publishedDateString);
      this.metaService.updateTag({
        name: 'article:published_time',
        content: publishedDate.toISOString(),
      });
      this.metaService.updateTag({
        name: 'published_date',
        content: publishedDate.toISOString(),
      });
    } else {
      this.metaService.removeTag(`name='article:published_time'`);
      this.metaService.removeTag(`name='privateation_date'`);
    }
  }

  private setModified(modifiedDateString?: string): void {
    if (modifiedDateString && (modifiedDateString !== 'undefined')) {
      const modifiedDate = new Date(modifiedDateString);
      this.metaService.updateTag({
        name: 'article:modified_time',
        content: modifiedDate.toISOString(),
      });
      this.metaService.updateTag({
        name: 'og:updated_time',
        content: modifiedDate.toISOString(),
      });
    } else {
      this.metaService.removeTag(`name='article:modified_time'`);
      this.metaService.removeTag(`name='og:updated_time'`);
    }
  }

  private setAuthor(author?: string): void {
    if (author && author.length) {
      this.metaService.updateTag({ name: 'article:author', content: author });
      this.metaService.updateTag({ name: 'author', content: author });
    } else {
      this.metaService.removeTag(`name='article:author'`);
      this.metaService.removeTag(`name='author'`);
    }
  }

  private setTwitterSiteCreator(site?: string): void {
    if (site) {
      this.metaService.updateTag({ name: 'twitter:site', content: site });
      this.metaService.updateTag({ name: 'twitter:creator', content: site });
    } else {
      this.metaService.removeTag(`name='twitter:site'`);
      this.metaService.removeTag(`name='twitter:creator'`);
    }
  }

  private setTwitterCard(card?: string): void {
    if (card) {
      this.metaService.updateTag({ name: 'twitter:card', content: card });
    } else {
      this.metaService.removeTag(`name='twitter:card'`);
    }
  }

  private setFbAppId(appId?: string): void {
    if (appId) {
      this.metaService.updateTag({ property: 'fb:app_id', content: appId });
    } else {
      this.metaService.removeTag(`property='fb:app_id'`);
    }
  }

  private setMetaTag(metaTag: SeoMetaTag): void {
    if (metaTag.value) {
      const metaTagObject = {
        [metaTag.attr]: metaTag.attrValue,
        content: metaTag.value,
      };
      this.metaService.updateTag(metaTagObject);
    } else {
      const selector = `${metaTag.attr}='${metaTag.attrValue}'`;
      this.metaService.removeTag(selector);
    }
  }

  private setMetaTags(metaTags: SeoMetaTag[]): void {
    for (const metaTag of metaTags) {
      this.setMetaTag(metaTag);
    }
  }

  private setLanguageAlternativeUrl(lang: string, url?: string): void {
    // first remove potential previous url
    const selector = `link[rel='alternate'][hreflang='${lang}']`;
    const languageAlternativeElement =
      this.documentRef.head.querySelector(selector);
    if (languageAlternativeElement) {
      this.documentRef.head.removeChild(languageAlternativeElement);
    }

    if (url && url.length) {
      const link: HTMLLinkElement = this.documentRef.createElement('link');
      link.setAttribute('rel', 'alternate');
      link.setAttribute('hreflang', lang);
      link.setAttribute('href', url);
      this.documentRef.head.appendChild(link);
    }
  }

  private setCanonicalUrl(url?: string): void {
    // first remove potential previous url
    const selector = `link[rel='canonical']`;
    const canonicalElement = this.documentRef.head.querySelector(selector);
    if (canonicalElement) {
      this.documentRef.head.removeChild(canonicalElement);
    }

    if (url && url.length) {
      const link: HTMLLinkElement = this.documentRef.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', url);
      this.documentRef.head.appendChild(link);
    }
  }
}
